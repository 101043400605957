import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import LoaderAnimation from "../components/LoaderAnimation"
import SEO from "../components/SEO"
import style from "./index.module.scss"
import Header from "../components/Header"
import VideoBackground from "../components/VideoBackground"
import LogoLaFresa from "../images/logoLaFresa"

export const query = graphql`
  {
    prismic {
      allLanding_pages {
        edges {
          node {
            _meta {
              lang
            }
            poster_desktop
            poster_mobile
            video_desktop {
              ... on PRISMIC__FileLink {
                name
              }
            }
            video_mobile {
              ... on PRISMIC__FileLink {
                name
              }
            }
            site_title
            site_description
            site_image
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const {
    _meta: { lang },
    poster_desktop,
    poster_mobile,
    video_mobile,
    video_desktop,
    site_title,
    site_description,
    site_image,
  } = data.prismic.allLanding_pages.edges[0].node

  const [playVideo, setPlayVideo] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setPlayVideo(true)
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [setPlayVideo])

  return (
    <>
      <SEO
        title={site_title}
        description={site_description}
        image={site_image}
        lang={lang}
      />
      <VideoBackground
        posterDesktop={poster_desktop}
        posterMobile={poster_mobile}
        videoMobile={video_mobile}
        videoDesktop={video_desktop}
        playVideo={playVideo}
      />
      <Header layout="index" />
      <main className={style.main}>
        <div className={style.logoContainer}>
          <LogoLaFresa />
        </div>
      </main>
      <LoaderAnimation playVideo={playVideo} />
    </>
  )
}

export default IndexPage
