import React from "react"

const LogoLaFresa = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 737 130.4">
    <path
      d="M197.3,67.1q0-16.2-4.8-22.2c-3.2-4.1-8-7.1-14.4-9.1s-14.7-3-25-3c-16.1,0-28.1,2.5-35.8,7.4s-11.7,12.6-12.1,23.1h33c.2-6.3,4.7-9.5,13.3-9.5,3.5,0,6.3.7,8.4,2.2a7.17,7.17,0,0,1,3.2,6.4c0,3.3-1.4,5.6-4.2,6.7s-8.3,2.2-16.4,3.1c-15.1,1.8-25.8,5.1-32.1,9.8s-9.5,11.5-9.5,20.4,3,15.9,8.9,20.8,13.9,7.4,23.9,7.4q20,0,30-11.1h.4v1.1a20.74,20.74,0,0,0,1.3,7.7h36.2c-2.9-4.1-4.3-10.6-4.3-19.7Zm-38.1,38.2c-2.7,2.8-6.3,4.1-10.9,4.1a12.44,12.44,0,0,1-7.9-2.5,7.37,7.37,0,0,1-3.2-6.1,7.83,7.83,0,0,1,2.3-6c1.6-1.4,4.6-2.7,9.1-3.9,7.4-1.8,12.3-3.3,14.5-4.7v7.6h.1C163.2,98.7,161.9,102.5,159.2,105.3Z"
    />
    <polygon points="39.5 0 0 0 0 128.1 96.1 128.1 96.1 95.3 39.5 95.3 39.5 0"/>
    <path
      d="M623.4,78.8c-5.4-4.5-14-7.9-25.7-10l-15.6-3c-5.4-1.1-8.1-3.1-8.1-6.1q0-7.2,10.8-7.2a12.67,12.67,0,0,1,8.1,2.8,9.49,9.49,0,0,1,3.9,6.9H629c-.4-9.7-4.5-17-12.4-22s-18.8-7.4-32.8-7.4c-13.6,0-24.4,2.6-32.2,7.9s-11.8,12.5-11.8,21.7c0,8.5,3.1,14.9,9.2,19.3s15.5,7.6,28.1,9.8c8.4,1.6,13.5,2.7,15.2,3.4a8.45,8.45,0,0,1,3.9,2.7,6.45,6.45,0,0,1,1.2,3.9c0,2.4-1.3,4.2-3.8,5.4a19,19,0,0,1-8.3,1.8c-8,0-12.6-3.6-13.8-10.9H537.6c.4,10.6,4.9,18.8,13.5,24.4s19.7,8.4,33.1,8.4c15.2,0,26.8-2.8,35-8.5s12.2-13.8,12.2-24.3C631.5,89.6,628.8,83.3,623.4,78.8Z"
    />
    <path
      d="M482.8,32.6c-14.5,0-26.3,4.7-35.5,14.1s-13.8,21.1-13.8,35.1c0,14.9,4.8,26.8,14.4,35.5s22.2,13.1,37.8,13.1c11.2,0,21.2-2.5,29.8-7.5A40.5,40.5,0,0,0,533.6,101H500.4c-2.9,4.4-7.9,6.6-14.9,6.6-5.1,0-9.3-1.5-12.5-4.6s-5.1-7.6-5.7-13.5h68.2Q535.5,32.65,482.8,32.6ZM467.6,71c1.7-10.4,7.8-15.6,18.1-15.6A15.38,15.38,0,0,1,497,59.9,15.08,15.08,0,0,1,501.5,71Z"
    />
    <path
      d="M732.7,108.3V67.1q0-16.2-4.8-22.2c-3.2-4.1-8-7.1-14.4-9.1s-14.7-3-25-3c-16.1,0-28.1,2.5-35.8,7.4S641,52.8,640.6,63.3h33c.2-6.3,4.7-9.5,13.3-9.5,3.5,0,6.3.7,8.4,2.2a7.17,7.17,0,0,1,3.2,6.4c0,3.3-1.4,5.6-4.2,6.7s-8.3,2.2-16.4,3.1c-15.1,1.8-25.8,5.1-32.1,9.8s-9.5,11.5-9.5,20.4,3,15.9,8.9,20.8,13.9,7.4,23.9,7.4q19.95,0,30-11.1h.4v1.1a20.74,20.74,0,0,0,1.3,7.7H737C734.1,124,732.7,117.4,732.7,108.3Zm-38.1-3c-2.7,2.8-6.3,4.1-10.9,4.1a12.44,12.44,0,0,1-7.9-2.5,7.37,7.37,0,0,1-3.2-6.1,7.83,7.83,0,0,1,2.3-6c1.6-1.4,4.6-2.7,9.1-3.9,7.4-1.8,12.3-3.3,14.5-4.7v7.6h.1C698.6,98.7,697.3,102.5,694.6,105.3Z"
    />
    <polygon points="255.9 128.1 295.3 128.1 295.3 79.3 346.4 79.3 346.4 48.8 295.3 48.8 295.3 32.8 354.9 32.8 354.9 0 255.9 0 255.9 128.1"/>
    <path
      d="M404,36.7c-4.2,2.7-7.8,7.1-10.9,13.2h-.4V35H358.6v93.1h35.5V90.4c0-18.2,7.2-27.3,21.7-27.3a42.51,42.51,0,0,1,13.8,2.3V34.1a40.92,40.92,0,0,0-10.4-1.4A26.76,26.76,0,0,0,404,36.7Z"
    />
  </svg>
)

export default LogoLaFresa
