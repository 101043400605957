import React, { useEffect, useState } from "react";
import style from "./LoaderAnimation.module.scss";
import Lottie from "lottie-react-web";
import * as animationData from "../animations/loaderAnimation";
import PropTypes from "prop-types";
import cx from "classnames";

const LoaderAnimation = props => {
  const { playVideo } = props;
  const [animationStopped, setAnimationStopped] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    speed: 2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  useEffect(() => {
    let timeoutID = null;
    if (playVideo) {
      timeoutID = setTimeout(() => setAnimationStopped(true), 120);
    }

    return () => {
      clearTimeout(timeoutID);
    };
  }, [playVideo]);

  const classNames = cx(style.container, {
    [style.isVisible]: !animationStopped
  });

  return (
    <div className={classNames}>
      <div className={style.lottieContainer}>
        <Lottie
          options={defaultOptions}
          speed={1.2}
          isStopped={animationStopped}
        />
      </div>
    </div>
  );
};

LoaderAnimation.propTypes = {
  playVideo: PropTypes.bool
};

export default LoaderAnimation;
